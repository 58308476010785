import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'photos',
    pathMatch: 'full'
  },
  { 
    path: 'photos',
    loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule),
    canActivate: [
      MsalGuard
    ]
  },
  // {
  //   // Needed for hash routing
  //   path: 'error',
  //   redirectTo: 'photos',
  //   //loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule)
  // },
  // {
  //   // Needed for hash routing
  //   path: 'state',
  //   redirectTo: 'photos',
  //   //loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule)
  // },
  // {
  //   // Needed for hash routing
  //   path: 'code',
  //   redirectTo: 'photos',
  //   //loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule)
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }