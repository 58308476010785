import { LogLevel } from '@azure/msal-browser';

export const environment = {
    production: false,
    apiUrl: 'https://firadmin-dev-api.azurewebsites.net/',
    webMapId: '779b7aff9658459ab1a24d3a68d13e52',
    gisEditorUrl: 'https://ecanmapsdev.ecan.govt.nz/viewer/?config=configs/FloodImageryRegister/firAdmin_config.json',
    auth: {
      clientId: 'af8bc076-2fd3-4364-a91f-6f8154912485',
      authority: 'https://login.microsoftonline.com/984befea-c12e-454e-9111-7b8d8da5e7e1',
      logLevel: LogLevel.Verbose,
      apiScope: 'api://6e57e6b3-0c6c-40f0-a76f-a464ac4ca0ee/access_as_user'
    }
};


