import { Injectable } from '@angular/core';
import { TdLoadingService } from '@covalent/core/loading';
import { MonoTypeOperatorFunction, Observable, defer } from 'rxjs';
import { finalize } from 'rxjs/operators';

let APP_LOADING_ID = 0;

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  static LOADING_DELAY = 250;

  instanceName: string;

  private startFullPageMacroRef: any;
  private startInstanceMacroRef: any;

  constructor(private loadingService: TdLoadingService) {
    this.instanceName = `busySpinnerInstance_${APP_LOADING_ID++}`;
  }

  public startInstance() {
    // Macro-Task
    this.startInstanceMacroRef = setTimeout(() => this.loadingService.register(this.instanceName), 0);
  }

  public startFullPage() {
    // Macro-Task
    this.startFullPageMacroRef = setTimeout(() => this.loadingService.register(), BusyService.LOADING_DELAY);
  }

  public stopInstance() {
    // Macro-Task
    clearTimeout(this.startInstanceMacroRef);
    setTimeout(() => this.loadingService.resolveAll(this.instanceName), 0);
  }

  public stopFullPage() {
    // Macro-Task
    clearTimeout(this.startFullPageMacroRef);
    setTimeout(() => this.loadingService.resolveAll(), 0);
  }

  public spinInstance<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => defer(() => {
      this.startInstance();
      return source.pipe(
        finalize(() => this.stopInstance())
      );
    });
  }

  public spinFullPage<T>(): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => defer(() => {
      this.startFullPage();
      return source.pipe(
        finalize(() => this.stopFullPage())
      );
    });
  }
}
