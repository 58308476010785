import { Component } from '@angular/core';
import { Router, GuardsCheckEnd, NavigationEnd, NavigationCancel } from '@angular/router';
import { BusyService } from './shared/services/busy.service';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fir-admin';

  constructor(
    private busyService: BusyService,
    private router: Router,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.busyService.stopFullPage();
    this.spinnerOnRouteChange();
  }

  logout() {
    this.msalService.logout();
  }

  private spinnerOnRouteChange() {
    this.router.events
      .subscribe(event => {
        if (event instanceof GuardsCheckEnd) {
          this.busyService.startFullPage();
        }
        if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
          this.busyService.stopFullPage();
        }
      });
  }
}
