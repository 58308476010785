<header>
  <nav>
    <mat-toolbar color="primary">
      <div class="title">FIR Admin</div>
      <button mat-button routerLink="/photos">
        Photo Collections
      </button>
      <span class="spacer"></span>
      <button mat-button (click)="logout()">
        Log out
      </button>
    </mat-toolbar>
  </nav>
</header>

<main>
  <router-outlet></router-outlet>
</main>